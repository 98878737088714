import React, { useState, useEffect } from "react"

import Layout from "../components/Layout"

//Icon import
import facebook from "../assets/icons/facebook-black.svg"
import linkedin from "../assets/icons/linkedin-black.svg"
import twitter from "../assets/icons/twitter-black.svg"

const GoodByeManualReportingEN = () => {
  const [url, setUrl] = useState("")
  const hashtags = "Peny,ExpenseManagement,CompanyCards,ExpenseFraud"
  const via = "usemuni"
  /* 
    Acilacak olan browser sekmesinin boyutu ve hangi toollarin gosterileceginin
    belirlenmesi
  */
  const params = "menubar=no,toolbar=no,status=no,width=570,height=570"

  const shareWithFunction = url => {
    window.open(url, "NewWindow", params)
  }

  useEffect(() => {
    if (typeof window !== "undefined") setUrl(window.location)
  })

  return (
    <Layout>
      <div id="blog-details">
        <div id="header">
          <div id="header-details-container">
            <span id="header-details-container-date">September 16, 2021</span>
            <div id="header-details-container-title">
              Goodbye to manual reporting!
            </div>
            <span id="header-details-container-author">
              by <span>Peny</span>
            </span>
          </div>
        </div>

        <div id="blog-body">
          <div id="blog-image" className="body_section">
            <img
              alt="blog container"
              src="https://website-blog-pictures.s3.eu-central-1.amazonaws.com/1631782599938.png"
            />
          </div>
          <span className="body_section">
            Technology continues to improve our lives day by day thanks to the
            convenience and efficiency that it provides. Despite all the recent
            technological developments, most companies today continue to use
            traditional methods for reporting and managing expenses. As a
            result, their employees waste their precious time and energy.
          </span>
          <div className="body_image_wrap_right">
            <img
              alt="blog container"
              src="https://website-blog-pictures.s3.eu-central-1.amazonaws.com/1631784388183.png"
            />
          </div>

          <span className="body_section">
            On top of this irretrievable loss, companies suffer from relatively
            widespread expense fraud due to the inadequacy of traditional check
            mechanisms. Unfortunately, this situation is getting worse.
            According to a survey conducted in the US in 2020, the number of
            false or invalid expenses has increased significantly in the last
            three years despite the technological tools at the companies’
            disposal.
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.netsuite.com/portal/business-benchmark-brainyard/industries/articles/cfo-central/expense-report.shtml"
            >
              [1]
            </a>
          </span>
          <span className="body_section">
            If companies are unable to get on top of the rising expense fraud
            cases, they are likely to suffer disastrous consequences. A study
            conducted in 2016 found that expense fraud causes $2.8 billion to
            American companies each year.
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.businesswire.com/news/home/20160329005218/en/Expense-Fraud-Costs-U.S.-Employers-2.8-Billion-per-Year-Shows-Chrome-River-Survey"
            >
              [2]
            </a>{" "}
            We estimate this yearly loss to be between ₺350 - ₺500 million for
            Turkish companies. In short, reporting expenses manually and
            managing them through traditional channels represent a high to your
            company’s finances.
          </span>
          <span className="body_section">
            However, the damage suffered is not limited to your company’s
            finances. If you ask your employees what is the most boring part of
            their job, what would they tell you?
          </span>
          <div className="body_image">
            <img
              alt="blog container"
              src="https://website-blog-pictures.s3.eu-central-1.amazonaws.com/1631783437311.png"
            />
          </div>
          <span className="body_section">
            It is likely that they will talk about filling Excel sheets by going
            through a pile of papers that their expenses have accumulated into
            over the preceding month. They would likely complain about chasing
            their managers to get their expenses approved as well.
          </span>
          <span className="body_section">
            We all know how difficult it is to keep track of the endless stream
            of receipts and invoices for the expenses we make. We also know how
            difficult it is to deal with this deluge every month as most we
            don’t have a way to report our expenses and get them approved
            immediately.
          </span>
          <span className="body_section">
            The worst thing is that expense reports made in such a stressful and
            chaotic period may cause inaccurate reporting and result in serious
            tax refund losses.
          </span>
          <span className="body_section">
            To sum up, using traditional methods of expense reporting and
            management creates a situation that doesn’t benefit your employees,
            your managers nor your company.
          </span>
          <span className="body_section">
            But this predicament is not inevitable!
          </span>
          <div className="body_image_wrap_right">
            <img
              alt="blog container"
              src="https://website-blog-pictures.s3.eu-central-1.amazonaws.com/1631783789872.png"
            />
          </div>
          <span className="body_section">
            Thanks to our smart expense management solutions, we transform
            expense reporting and management processes into a stress-free,
            simple, and enjoyable time.
          </span>
          <span className="body_section">
            All your employees have to do is take a picture of their receipt or
            invoice and upload it to your company portal with Muni.
          </span>
          <span className="body_section">
            Afterwards, we enter these expenses into approval flows that you
            specified and inform the responsible manager immediately. We also
            check the expenses according to the spend time, amount, and category
            limits that you set, and inform you if one of these limits are
            triggered. This way you avoid any unwanted or unexpected expenses.
            Thanks to our advanced fraud detection system, we also instantly
            inform you about any expenses whose data do not match their proofs.
          </span>
          <span className="body_section">
            Sign up with Peny to get more and get started on enjoying smooth
            expense management!
          </span>
          <div className="body_section_references">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.netsuite.com/portal/business-benchmark-brainyard/industries/articles/cfo-central/expense-report.shtml"
            >
              [1]
            </a>
            O'Brien, Megan. “The Future of Expense Reports: Spend Less Time and
            Effort on Reimbursement.” NetSuite. Accessed June 13, 2021.
          </div>
          <div className="body_section_references">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.businesswire.com/news/home/20160329005218/en/Expense-Fraud-Costs-U.S.-Employers-2.8-Billion-per-Year-Shows-Chrome-River-Survey"
            >
              [2]
            </a>{" "}
            Wheatcroft, Tim. “Expense Fraud COSTS U.S. EMPLOYERS $2.8 Billion
            per Year, Shows CHROME River Survey.” Expense Fraud Costs U.S.
            Employers $2.8 Billion per Year, Shows Chrome River Survey |
            Business Wire, March 29, 2016.
          </div>
        </div>

        <div id="tags-container">
          <span id="tags-container-title">Tags:</span>
          <div id="tag-container">
            <div className="tag_button">
              <span>Peny</span>
            </div>
            <div className="tag_button">
              <span>Expenses</span>
            </div>
            <div className="tag_button">
              <span>Expense Managements</span>
            </div>
          </div>
        </div>

        <hr />

        <div id="social-container">
          <span>Share:</span>
          <div id="buttons-container">
            <div
              className="social_button"
              onClick={() => {
                const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`
                shareWithFunction(shareUrl)
              }}
            >
              <img src={linkedin} alt="Linkedin" />
            </div>
            <div
              className="social_button"
              onClick={() => {
                const shareUrl = `http://www.facebook.com/sharer/sharer.phpu=${url}`
                shareWithFunction(shareUrl)
              }}
            >
              <img src={facebook} alt="Facebook" />
            </div>
            <div
              className="social_button"
              onClick={() => {
                const shareUrl = `https://twitter.com/intent/tweet?url=${url}&text=&via=${via}&hashtags=${hashtags}`
                shareWithFunction(shareUrl)
              }}
            >
              <img src={twitter} alt="Twitter" />
            </div>
            {/* <div className="social_button">
            <img src={instagram} alt="Instagram" />
          </div> */}
          </div>
        </div>

        <div id="about-author-wrapper">
          <div id="about-author-card">
            <div id="about-author-card-top">
              <div id="about-author-card-top-avatar">
                <img
                  alt="author avatar"
                  src="https://media-exp1.licdn.com/dms/image/C4E0BAQGIWKhm4dCqIA/company-logo_200_200/0/1609746202791?e=1656547200&v=beta&t=1T2uU6-cLPmvNzB9pNn0-sC7U1H3-fmYUPsG-BUeAmo"
                />
              </div>
              <div id="about-author-card-top-info">
                <span>Peny</span>
              </div>
            </div>
            <div id="about-author-card-middle">About Author</div>

            <span id="about-author-card-bottom">
              Peny is a fintech start-up working to revolutionize the way for
              employees to make business expenditures and companies to manage
              their expenses. We are marrying smart company cards with advanced
              expense management software so that expenses become a breeze for
              companies that suffer millions of dollars in expense management
              costs every year.
            </span>
          </div>
        </div>

        <div id="leave-comment"></div>
      </div>
    </Layout>
  )
}

export default GoodByeManualReportingEN
